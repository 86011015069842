// COLOR SELECTOR
.colorGrid {
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
  padding: 8px;
  overflow-x: hidden;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  margin-bottom: 8px;
}

.colorButton {
  margin: 0;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  width: 16px;
  height: 16px;
  cursor: pointer;
  border: none;
  box-sizing: border-box;
  display: inline-block;
  line-height: 20px;
  padding: 6px 16px;
  position: relative;
  text-decoration: none;

  &:hover {
    filter: saturate(85%) brightness(85%);
  }

  &:active {
    filter: saturate(70%) brightness(70%);
  }

  &.selected {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0079bf;
    filter: none;
  }
}
