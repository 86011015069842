.formsModal {
  padding: 0px 16px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  display: flex;
  justify-content: space-between;
}

.formsPage {
  padding: 8px 16px;
  font-size: 14px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;

  .header {
    display: flex;
    gap: 8px;
    margin-bottom: 8px;
    align-items: center;
  }
}

.loadingSpinnerContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.relativeContainer {
  position: relative;
}

// Left column
.fieldsMenu {
  position: sticky;
  top: 0;
  width: 200px;
  border-radius: 4px;
  height: calc(100vh - 32px);
  border-right: solid 1px var(--ds-border, #eeeeee);
  padding: 16px;
  overflow: scroll;

  h2 {
    margin: 0;
  }
}

.centerDiv {
  width: 100%;
  display: flex;
  justify-content: center;
}

// Center column
.formContent {
  margin: 16px 0;
  padding: 16px 32px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 420px;
  border-radius: 4px;
  background-color: var(--ds-surface, #ffffff);
}

// Right column
.formSettings {
  position: sticky;
  top: 0;
  width: 240px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: calc(100vh - 32px);
  border-left: solid 1px var(--ds-border, #eeeeee);
  padding: 16px 0 16px 16px;
}

.settingsSections {
  overflow: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
}

.settingsSection {
  border-top: solid 1px var(--ds-border, #eeeeee);
  padding: 8px 0;
}

.settingsSubsection {
  border-bottom: solid 1px var(--ds-border, #eeeeee);
  padding: 8px 0;
}

.fieldsList {
  margin-top: 8px;
  margin-bottom: 16px;
  display: flex;
  flex-direction: column;
  min-height: 200px;

  &.empty {
    border: dashed 1px var(--ds-border, #eeeeee);
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    line-height: 16px;
    color: var(--ds-text-subtle, #8993a4);
    position: relative;

    .emptyMessage {
      position: absolute;
      z-index: 0;
    }
  }
}

.dragAndDropTip {
  font-size: 12px;
  line-height: 16px;
  color: var(--ds-text-subtle, #8993a4);
  position: relative;
  margin-top: 8px;
}

.label {
  color: var(--ds-text-subtle, #5e6c84);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 16px;
  margin-top: 0;
  margin-bottom: 4px;
}

.fieldTokenList {
  margin-top: 8px;
}

.fieldTokenClone {
  transform: none !important;
  border-radius: 4px;
  background: var(--ds-surface-sunken, #ffffff);
  display: flex;
  align-items: center;
  gap: 8px;
  height: 42px;
  margin-bottom: 8px;
}

.draggableFieldWrapper {
  margin-bottom: 8px;
}

.addLogo,
.logo {
  height: 38px;
  width: 38px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: dashed 1px var(--ds-border, #eeeeee);
  color: var(--ds-text-subtle, #8993a4);

  input {
    opacity: 0;
    height: 100%;
    width: 100%;
  }
}

.logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;

  .removeLogoIcon {
    display: none;
  }

  &:hover {
    opacity: 0.8;

    .removeLogoIcon {
      display: block;
    }
  }
}
