.mainContainer {
  max-width: 420px;
  margin: 0px auto;
  padding: 1rem 1.25rem;
  background: initial;
  border-radius: 8px;

  background-color: var(--ds-surface, #ffffff);
  border-radius: 4px;
}

.loadingSpinnerContainer {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.introductionMessage {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 16px;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
}

.fieldError {
  color: var(--ds-text-danger, #ff0000);
  font-size: 0.75rem;
  margin-top: 4px;
}

.confirmationMessage {
  font-size: 1.25rem;
  line-height: 1.75rem;
  margin-bottom: 16px;
}

.poweredBy {
  font-size: 0.75rem;
  line-height: 1rem;
  margin-top: 16px;
  text-align: center;
}

.logo {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  height: 38px;
  width: 38px;
  min-width: 38px;
  border-radius: 4px;
}
