.imageButtonGrid {
  -webkit-overflow-scrolling: touch;
  transform: translate3d(0, 0, 0);
  display: grid;
  gap: 8px;
  padding: 4px;

  overflow-x: hidden;
  grid-template-columns: 1fr 1fr 1fr;
}

// Images
.imageButton {
  margin: 0;
  border-radius: 3px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  box-shadow: none;
  height: 48px;
  width: 72px;
  cursor: pointer;

  &:hover {
    filter: saturate(85%) brightness(85%);
  }

  &:active {
    filter: saturate(70%) brightness(70%);
  }

  &.selected {
    box-shadow: 0 0 0 2px #ffffff, 0 0 0 4px #0079bf;
    filter: none;
  }
}
