.fieldToken {
  margin-bottom: 8px;
  padding: 8px;
  border-radius: 4px;
  border: solid 1px var(--ds-border, #eeeeee);
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: var(--ds-surface-raised, #ffffff);
  color: var(--ds-text, #172b4d);
}

.emptyCustomFieldToken {
  border-radius: 4px;
  padding: 8px;
  border: solid 1px var(--ds-border, #eeeeee);
  font-size: 12px;
  line-height: 16px;
  color: var(--ds-text-subtle, #999999);
}
