.fieldContainer {
  border-radius: 4px;
  border: solid 1px var(--ds-border, #eeeeee);
  padding: 16px;
  background-color: var(--ds-surface, #ffffff);
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: var(--ds-text, #172b4d);
  position: relative;
}

.fieldName {
  font-size: 14px;
  width: 100%;
  padding-left: 8px;
}

.fieldHeader {
  display: flex;
  align-items: center;
  gap: 8px;
}

.editActions {
  display: flex;
  align-items: center;
  gap: 8px;

  position: absolute;
  right: 8px;
  top: 8px;

  button {
    width: 24px;
  }
}

.expandEditActions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 8px;
}

.fieldSection {
  // border-bottom: solid 1px #eeeeee;
  // padding: 8px 0;
}

.fieldIsLinkedToText {
  font-size: 12px;
  line-height: 16px;
  position: relative;
  color: var(--ds-text-subtle, #172b4d);
}

.editLabel {
  color: var(--ds-text-subtle, #5e6c84);
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
  margin-top: 16px;
  margin-top: 0;
  margin-bottom: 8px;
}

// ======== Final form styles ========
.formFieldContainer {
  font-size: 14px;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 8px;

  .label {
    font-size: 14px;
    font-weight: bold;
    color: var(--ds-text-subtle, #5e6c84);

    &.isPreview {
      max-width: calc(100% - 56px);

      input {
        cursor: grab;
      }
    }
  }

  .description {
    font-size: 12px;
    line-height: 16px;
  }
}

.dateTimeContainer {
  display: flex;
  gap: 8px;
}

.calendarContainer {
  display: flex;
  justify-content: center;
}

.uploadBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  border: dashed 1px var(--ds-border, #eeeeee);
  border-radius: 4px;
  height: 100px;
  width: 100%;
  cursor: pointer;
  margin: 8px 0;

  .uploadIcon {
    width: 24px;
    height: 24px;
  }

  .uploadText {
    font-size: 12px;
    line-height: 16px;
    color: var(--ds-text-subtle, #8993a4);
  }
}

.options {
  display: flex;
  flex-direction: column;
  gap: 8px;

  .option {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: grab;
  }
}

.addOption {
  margin-top: 8px;
}
